import { Product } from '~/composables/useAPI/types'

export default defineNuxtRouteMiddleware((to) => {
  const { data } = useAuth()
  const { $updateAbility, $can } = useNuxtApp()
  $updateAbility(data.value)

  if (!$can('product', Product.CRITICAL_DATES)) {
    throw createError({
      statusCode: 403,
      statusMessage: 'Unauthorized',
    })
  }
})
